var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header align-items-center justify-content-between"},[_c('div',{staticClass:"input-group input-group-flush d-flex flex-row-reverse"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.search),expression:"search",modifiers:{"trim":true}}],staticClass:"form-control list-search",attrs:{"type":"search","placeholder":"Search"},domProps:{"value":(_vm.search)},on:{"keyup":_vm.applySearch,"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._m(0)])]),_c('div',{staticClass:"card-body p-0"},[_c('b-table',{attrs:{"striped":"","hover":"","selectable":"","responsive":"","items":_vm.tableData,"fields":_vm.columns,"current-page":_vm.currentPage,"busy":_vm.loading,"empty-text":'No bus captains available.',"show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(scope){return [_c('h4',{staticClass:"text-center"},[_vm._v(_vm._s(scope.emptyText))])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}}),_c('p',{staticClass:"text-center mt-4"},[_c('strong',[_vm._v("Loading...")])])])]},proxy:true},{key:"cell(user)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('user-avatar',{staticClass:"mr-1",attrs:{"user":data.item.user,"size":"sm"}}),_c('router-link',{staticClass:"sh-page-link",attrs:{"to":{
									name: 'ShowUser',
									params: { userId: data.item.user.id }
								}}},[_vm._v(_vm._s(`${data.item.user.fname} ${data.item.user.lname}`)+" ")])],1)]}},{key:"cell(phone)",fn:function(data){return [_c('router-link',{staticClass:"sh-page-link",attrs:{"to":{
								name: 'ShowUser',
								params: { userId: data.item.user.id }
							}}},[_vm._v(" "+_vm._s(data.item.user.phone))])]}},{key:"cell(email)",fn:function(data){return [_c('router-link',{staticClass:"sh-page-link",attrs:{"to":{
								name: 'ShowUser',
								params: { userId: data.item.user.id }
							}}},[_vm._v(_vm._s(data.item.user.email)+" ")])]}},{key:"cell(route)",fn:function(data){return [_c('router-link',{staticClass:"sh-page-link",attrs:{"to":{ name: 'ShowRoute', params: { routeId: data.item.route.id } }}},[_vm._v(_vm._s(data.item.route.route_code))])]}},{key:"cell(itinerary)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.itinerary.trip_time)+" ")]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"dropdown"},[_c('a',{attrs:{"href":"#","role":"button","id":'dropdownMenuLink_' + data.reference,"data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('span',[_c('svg',{attrs:{"width":"16","height":"4","viewBox":"0 0 16 4","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5C2.82843 3.5 3.5 2.82843 3.5 2C3.5 1.17157 2.82843 0.5 2 0.5Z","fill":"#444854"}}),_c('path',{attrs:{"d":"M6.5 2C6.5 1.17157 7.17157 0.5 8 0.5C8.82843 0.5 9.5 1.17157 9.5 2C9.5 2.82843 8.82843 3.5 8 3.5C7.17157 3.5 6.5 2.82843 6.5 2Z","fill":"#444854"}}),_c('path',{attrs:{"d":"M12.5 2C12.5 1.17157 13.1716 0.5 14 0.5C14.8284 0.5 15.5 1.17157 15.5 2C15.5 2.82843 14.8284 3.5 14 3.5C13.1716 3.5 12.5 2.82843 12.5 2Z","fill":"#444854"}})])])]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":'dropdownMenuLink_' + data.reference}},[_c('a',{staticClass:"dropdown-item text-danger",on:{"click":function($event){$event.preventDefault();return _vm.removeAsBusCaptain(data.item.id, data.item.user)}}},[_vm._v("Remove as captain")])])])]}}])})],1),(_vm.totalRecords)?_c('div',{staticClass:"card-footer"},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRecords,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"input-group-text border-0"},[_c('i',{staticClass:"fe fe-search"})])
}]

export { render, staticRenderFns }