<template>
	<div class="main-content">
		<div class="row mt-4">
			<div class="col-12">
				<div class="card">
					<div class="card-header align-items-center justify-content-between">
						<div class="input-group input-group-flush d-flex flex-row-reverse">
							<input @keyup="applySearch" v-model.trim="search" class="form-control list-search" type="search"
								placeholder="Search" />
							<span class="input-group-text border-0">
								<i class="fe fe-search"></i>
							</span>
						</div>
					</div>
					<div class="card-body p-0">
						<b-table striped hover selectable responsive :items="tableData" :fields="columns" :current-page="currentPage"
							:busy="loading" :empty-text="'No bus captains available.'" show-empty>
							<template #empty="scope">
								<h4 class="text-center">{{ scope.emptyText }}</h4>
							</template>

							<template #table-busy>
								<div class="d-flex flex-column justify-content-center align-items-center">
									<div class="spinner-border" role="status"></div>
									<p class="text-center mt-4"><strong>Loading...</strong></p>
								</div>
							</template>

							<template #cell(user)="data">
								<div class="d-flex align-items-center">
									<user-avatar :user="data.item.user" size="sm" class="mr-1"></user-avatar>
									<router-link class="sh-page-link" :to="{
										name: 'ShowUser',
										params: { userId: data.item.user.id }
									}">{{ `${data.item.user.fname} ${data.item.user.lname}` }}
									</router-link>
								</div>
							</template>
							<template #cell(phone)="data">
								<router-link class="sh-page-link" :to="{
									name: 'ShowUser',
									params: { userId: data.item.user.id }
								}"> {{ data.item.user.phone }}</router-link>
							</template>
							<template #cell(email)="data">
								<router-link class="sh-page-link" :to="{
									name: 'ShowUser',
									params: { userId: data.item.user.id }
								}">{{ data.item.user.email }}
								</router-link>
							</template>
							<template #cell(route)="data">
								<router-link class="sh-page-link"
									:to="{ name: 'ShowRoute', params: { routeId: data.item.route.id } }">{{ data.item.route.route_code }}</router-link>
							</template>
							<template #cell(itinerary)="data">
								{{ data.item.itinerary.trip_time }}
							</template>

							<template #cell(action)="data">
								<div class="dropdown">
									<a class="" href="#" role="button" :id="'dropdownMenuLink_' + data.reference" data-toggle="dropdown"
										aria-haspopup="true" aria-expanded="false">
										<span>
											<svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5C2.82843 3.5 3.5 2.82843 3.5 2C3.5 1.17157 2.82843 0.5 2 0.5Z"
													fill="#444854" />
												<path
													d="M6.5 2C6.5 1.17157 7.17157 0.5 8 0.5C8.82843 0.5 9.5 1.17157 9.5 2C9.5 2.82843 8.82843 3.5 8 3.5C7.17157 3.5 6.5 2.82843 6.5 2Z"
													fill="#444854" />
												<path
													d="M12.5 2C12.5 1.17157 13.1716 0.5 14 0.5C14.8284 0.5 15.5 1.17157 15.5 2C15.5 2.82843 14.8284 3.5 14 3.5C13.1716 3.5 12.5 2.82843 12.5 2Z"
													fill="#444854" />
											</svg>
										</span>
									</a>
									<div class="dropdown-menu" :aria-labelledby="'dropdownMenuLink_' + data.reference">
										<a class="dropdown-item text-danger"
											@click.prevent="removeAsBusCaptain(data.item.id, data.item.user)">Remove as captain</a>
									</div>
								</div>
							</template>
						</b-table>
					</div>
					<div class="card-footer" v-if="totalRecords">
						<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import busCaptainService from '@/api/bus_captain'
import UserAvatar from '@/components/core/UserAvatar'
export default {
  components: { UserAvatar },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      totalRecords: 0,
      loading: false,
      tableData: [],
      columns: [
        {
          key: 'user',
          label: 'User'
        },
        {
          key: 'phone',
          label: 'Phone'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'route',
          label: 'Route'
        },
        {
          key: 'itinerary',
          label: 'Itinerary'
        },
        {
          key: 'action',
          label: ''
        }
      ],
      search: null
    }
  },
  created() {
    this.fetchBusCaptains()
  },
  watch: {
    currentPage(value, oldValue) {
      if (value !== oldValue) {
        this.fetchBusCaptains(false)
      }
    }
  },
  methods: {
    fetchBusCaptains(showLoader = true) {
      if (!this.loading) {
        if (showLoader) {
          this.loading = true
        }
        busCaptainService.fetchAllBusCaptains(this.currentPage, this.perPage, this.search).then((res) => {
          this.tableData = res.data.data
          this.totalRecords = res.data.metadata?.total
        }).catch((e) => {
          
        }).finally(() => this.loading = false)
      }
    },
    removeAsBusCaptain(id, user) {
      busCaptainService
        .removeAsBusCaptain(id, this.$swal, `${user.fname} ${user.lname}`,
          {
            successFxn: () => {
              this.currentPage = 1
              this.fetchBusCaptains()
            },
            errorFxn: (err) => {}
          })
    },
    viewUser(user) {
      this.$router.push({ name: 'ShowUser', params: { userId: user.id } })
    },
    applySearch(event) {
      if (event.key === 'Enter' || event.keyCode === 13) {
        this.refreshTable(true)
      } else if (!this.search) {
        this.refreshTable(false)
      }
    },
    refreshTable(searchEnabled = false) {
      if (!searchEnabled) {
        this.search = ''
      }
      if (this.currentPage === 1) {
        this.fetchBusCaptains()
      } else {
        // change current page to trigger effect on property watch
        this.currentPage = 1
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@use "src/assets/scss/partials/typography";
</style>
